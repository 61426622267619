import { getConfig } from './../store/config/config.selectors';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import * as SessionSelectors from './../store/session/session.selectors';
import { Session } from '../models/session.model';
import { Config } from './../store/config/config.reducer';
import * as _ from 'lodash';

@Injectable()
export class GsHttpInterceptor implements HttpInterceptor {
  private headers = new HttpHeaders({
    // @TODO: Refactor, when GD-3481 is done
    // 'Content-Type': 'application/json, text/plain',
    Accept: 'application/json',
    'x-api-client': 'panel'
  });

  sessionData?: Session;
  config?: Config;

  constructor(private store: Store) {
    this.store
      .select(SessionSelectors.getSessionData)
      .pipe(filter(val => val !== undefined))
      .subscribe((_session: Session) => {
        if ('token' in _session && 'user_uuid' in _session) {
          this.headers = this.headers.set('X-Auth-Token', _session.token!);
          this.headers = this.headers.set('X-Auth-UserId', _session.user_uuid!);
          if (_session.project_uuid) this.headers = this.headers.set('X-Auth-ProjectId', _session.project_uuid);
          if (_session.contract_uuid) this.headers = this.headers.set('X-Auth-ContractId', _session.contract_uuid);
          if (_session.partner_uuid) this.headers = this.headers.set('X-Auth-PartnerId', _session.partner_uuid);
          this.sessionData = _session;
        }
      });

    this.store
      .select(getConfig)
      .pipe(
        filter(config => !_.isEmpty(config)),
        take(1)
      )
      .subscribe((config: Config) => (this.config = config));
  }

  private proxyUrl = environment.useProxy ? environment.proxyUrl.replace(/\/?$/, '/') : '';
  private apiUrl = environment.apiUrl.replace(/\/?$/, '');

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let whitelisted = false;

    environment.fetchUrlWhitelist.forEach(regex => {
      if (req.url.match(regex)) {
        whitelisted = true;
      }
    });

    if (req.url.indexOf('management') !== -1) {
      req = req.clone({
        withCredentials: false,
        url: `${this.proxyUrl}${this.config?.core_api}${req.url}`,
        headers: this.headers
      });
    } else if (!whitelisted) {
      req = req.clone({
        withCredentials: true,
        url: `${this.proxyUrl}${this.apiUrl}${req.url}`,
        headers: this.headers
      });
    } else if (req.url.indexOf('partner') !== -1) {
      req = req.clone({
        withCredentials: false,
        url: `${this.proxyUrl}${req.url}`,
        headers: this.headers
      });
    }

    if (this.sessionData) {
      req = req.clone({
        url: req.url
          .replace('contracts/current', 'contracts/' + this.sessionData.contract_uuid)
          .replace('projects/current', 'projects/' + this.sessionData.project_uuid)
          .replace('users/current', 'users/' + this.sessionData.user_uuid)
      });
    }

    return next.handle(req);
  }
}
